'use client';

import React, { useEffect, useMemo, useRef } from 'react';
import { useUiState } from '@/stores/ui-state';
import StoreLogo from '@/components/StoreLogo';
import SearchComponent from '@/components/SearchComponent';
import CartComponent from '@/components/CartComponent';
import BurgerComponent from '@/components/BurgerComponent';
import NavTop from '@/components/NavTop';
import MessageBanner, { BannerMessage } from '@/components/MessageBanner';
import { useStoreTheme } from '@/hooks/use-store-theme';
import { useStoreData } from '@/hooks/use-store-data';
import { useHandleScroll } from '@/hooks/use-handle-scroll';
import { useCollectionsNavigation } from '@/hooks/use-collections-navigation';
import styles from './index.module.css';

const NAV_TOP_BREAKPOINT = 750;

export type HeaderOptions = {
  hideSearch?: boolean;
};

// optional HeaderOptions to version with all members required (defaults applied)
function expandHeaderOptions(options?: HeaderOptions): Required<HeaderOptions> {
  const { hideSearch = false } = options || {};
  return {
    hideSearch,
  };
}

export type Props = {
  options?: HeaderOptions;
};

const Header: React.FC<Props> = ({ options: rawOptions }) => {
  const navTopRef = useRef<HTMLDivElement>(null);
  const { navMode, setNavMode, navUnderline } = useUiState();

  const { hideSearch } = expandHeaderOptions(rawOptions);
  const storeTheme = useStoreTheme();
  const { storeData } = useStoreData();
  const collectionsNavData = useCollectionsNavigation(true);

  useEffect(() => {
    const elementWidth = navTopRef.current?.offsetWidth || 0;
    setNavMode(elementWidth <= NAV_TOP_BREAKPOINT ? 'top' : 'bottom');
  }, [setNavMode, navTopRef.current?.offsetWidth]);

  const headerStyles = storeTheme?.styles?.header;
  const headerBackgroundColor = headerStyles?.bgStyles?.backgroundColor || '';
  const textColor = headerStyles?.textStyles?.color || '';
  const topNav = navMode === 'top';
  const currentStoreLogoStyle = !topNav ? styles.storeLogoContent : '';

  // convert promo codes for banner messages
  const bannerMessages = useMemo<BannerMessage[]>(() => {
    return storeData?.promoCodes?.map((p) => ({ promoCode: p })) || [];
  }, [storeData]);

  const animationClass = useHandleScroll();

  return (
    <div className={`${styles.headerMainContainer} ${animationClass}`}>
      {bannerMessages.length > 0 && <MessageBanner messages={bannerMessages} />}
      <div className={styles.headerContainer} style={{ backgroundColor: headerBackgroundColor, color: textColor }}>
        <header className={styles.headerContent}>
          <div className={styles.headerMain}>
            <BurgerComponent isBurgerShowing={!topNav} />
            <StoreLogo storLogoStyle={currentStoreLogoStyle} />
            <NavTop
              ref={navTopRef}
              show={topNav}
              collectionsNavigation={collectionsNavData}
              enableNavUnderline={navUnderline}
            />
            <div className={styles.headerEnd}>
              {!hideSearch && <SearchComponent />}
              <CartComponent cartComponentStyle={{ color: textColor }} />
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;

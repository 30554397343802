import { useState, useEffect, useCallback, useMemo } from 'react';
import { MEDIUM_SCREEN_SIZE, DISTANCE_FROM_TOP } from '@/constants';
import useWindowDimensions from '@/hooks/use-window-dimensions';

/**
 * Custom hook to handle scroll-based animations on mobile devices.
 * It adjusts the animation class based on the scroll position.
 */
export const useHandleScroll = () => {
  // State to keep track of the previous scroll position.
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  // State to manage the animation class.
  const [animationClass, setAnimationClass] = useState('');

  // Use window dimensions to determine if the device is mobile.
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return width !== null && width <= MEDIUM_SCREEN_SIZE;
  }, [width]);

  // Callback to handle scroll events.
  const handleScroll = useCallback(() => {
    // Current vertical scroll position.
    const currentScrollPos = window.scrollY;

    // Determine visibility based on scroll direction and position.
    const visible = prevScrollPos > currentScrollPos || currentScrollPos < DISTANCE_FROM_TOP;

    // Set the animation class based on visibility and if it's a mobile device.
    if (isMobile && currentScrollPos >= DISTANCE_FROM_TOP) {
      setAnimationClass(visible ? 'slide-bottom' : 'slide-top');
    } else {
      setAnimationClass('');
    }

    // Update the previous scroll position.
    setPrevScrollPos(currentScrollPos);
  }, [isMobile, prevScrollPos]);

  // Attach the handleScroll function to the scroll event listener.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Return the animation class to be used by components.
  return animationClass;
};

import React from 'react';

export { Link as LinkIcon } from 'react-feather';

export const FacebookIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Facebook</title>
      <path d='M13.897 20.9969V12.8009H16.662L17.073 9.59191H13.897V7.54791C13.897 6.62191 14.155 5.98791 15.484 5.98791H17.168V3.12691C16.349 3.03891 15.525 2.99691 14.701 2.99991C12.257 2.99991 10.579 4.49191 10.579 7.23091V9.58591H7.83197V12.7949H10.585V20.9969H13.897Z' />
    </svg>
  );
};

export const GitHubIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Github</title>
      <path
        fillRule='evenodd'
        d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export const InstagramIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Instagram</title>
      <path d='M12.499 7.37695C9.94498 7.37695 7.87598 9.44695 7.87598 12C7.87598 14.554 9.94498 16.624 12.499 16.624C15.051 16.624 17.122 14.554 17.122 12C17.122 9.44695 15.051 7.37695 12.499 7.37695ZM12.499 15.004C10.84 15.004 9.49498 13.659 9.49498 12.001C9.49498 10.342 10.84 8.99795 12.499 8.99795C14.158 8.99795 15.501 10.342 15.501 12.001C15.501 13.659 14.158 15.004 12.499 15.004Z' />
      <path d='M17.306 8.28491C17.9014 8.28491 18.384 7.80227 18.384 7.20691C18.384 6.61154 17.9014 6.12891 17.306 6.12891C16.7107 6.12891 16.228 6.61154 16.228 7.20691C16.228 7.80227 16.7107 8.28491 17.306 8.28491Z' />
      <path d='M21.033 6.11088C20.564 4.90188 19.609 3.94588 18.4 3.47888C17.701 3.21588 16.962 3.07488 16.214 3.05888C15.251 3.01688 14.946 3.00488 12.504 3.00488C10.062 3.00488 9.74901 3.00488 8.79401 3.05888C8.04801 3.07388 7.30901 3.21488 6.61001 3.47888C5.40001 3.94588 4.44501 4.90188 3.97701 6.11088C3.71401 6.81088 3.57301 7.54888 3.55801 8.29688C3.51501 9.25888 3.50201 9.56388 3.50201 12.0069C3.50201 14.4489 3.50201 14.7599 3.55801 15.7169C3.57301 16.4649 3.71401 17.2029 3.97701 17.9039C4.44601 19.1119 5.40101 20.0679 6.61101 20.5359C7.30701 20.8079 8.04601 20.9619 8.79601 20.9859C9.75901 21.0279 10.064 21.0409 12.506 21.0409C14.948 21.0409 15.261 21.0409 16.216 20.9859C16.963 20.9709 17.702 20.8289 18.402 20.5669C19.611 20.0979 20.566 19.1429 21.035 17.9339C21.298 17.2339 21.439 16.4959 21.454 15.7479C21.497 14.7859 21.51 14.4809 21.51 12.0379C21.51 9.59488 21.51 9.28488 21.454 8.32788C21.441 7.56988 21.301 6.81888 21.033 6.11088ZM19.815 15.6429C19.808 16.2189 19.704 16.7899 19.504 17.3309C19.199 18.1179 18.578 18.7399 17.792 19.0419C17.257 19.2409 16.693 19.3449 16.122 19.3529C15.172 19.3969 14.904 19.4079 12.468 19.4079C10.03 19.4079 9.78101 19.4079 8.81301 19.3529C8.24401 19.3459 7.67801 19.2409 7.14401 19.0419C6.35501 18.7409 5.73001 18.1189 5.42501 17.3309C5.22901 16.7969 5.12301 16.2319 5.11401 15.6619C5.07101 14.7119 5.06101 14.4439 5.06101 12.0079C5.06101 9.57088 5.06101 9.32188 5.11401 8.35288C5.12101 7.77688 5.22501 7.20688 5.42501 6.66588C5.73001 5.87688 6.35501 5.25588 7.14401 4.95388C7.67801 4.75588 8.24401 4.65088 8.81301 4.64288C9.76401 4.59988 10.031 4.58788 12.468 4.58788C14.905 4.58788 15.155 4.58788 16.122 4.64288C16.693 4.64988 17.257 4.75488 17.792 4.95388C18.578 5.25688 19.199 5.87888 19.504 6.66588C19.7 7.19988 19.806 7.76488 19.815 8.33488C19.858 9.28588 19.869 9.55288 19.869 11.9899C19.869 14.4259 19.869 14.6879 19.826 15.6439H19.815V15.6429Z' />
    </svg>
  );
};

export const TwitterIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Twitter</title>
      <path d='M20.133 7.99704C20.146 8.17204 20.146 8.34604 20.146 8.52004C20.146 13.845 16.093 19.981 8.686 19.981C6.404 19.981 4.284 19.32 2.5 18.172C2.824 18.209 3.136 18.222 3.473 18.222C5.356 18.222 7.089 17.586 8.474 16.501C6.703 16.464 5.219 15.304 4.707 13.708C4.956 13.745 5.206 13.77 5.468 13.77C5.829 13.77 6.192 13.72 6.529 13.633C4.682 13.259 3.299 11.638 3.299 9.68004V9.63004C3.836 9.92904 4.459 10.116 5.119 10.141C4.034 9.41904 3.323 8.18404 3.323 6.78704C3.323 6.03904 3.522 5.35304 3.871 4.75504C5.854 7.19804 8.835 8.79504 12.177 8.97004C12.115 8.67004 12.077 8.35904 12.077 8.04704C12.077 5.82704 13.873 4.01904 16.105 4.01904C17.265 4.01904 18.312 4.50504 19.048 5.29104C19.958 5.11604 20.83 4.77904 21.604 4.31804C21.305 5.25304 20.668 6.03904 19.833 6.53804C20.644 6.45004 21.43 6.22604 22.152 5.91404C21.604 6.71204 20.919 7.42304 20.133 7.99704Z' />
    </svg>
  );
};

export const YouTubeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Youtube</title>
      <path d='M22.093 7.20301C21.863 6.34501 21.188 5.66801 20.331 5.43701C18.765 5.00701 12.5 5.00001 12.5 5.00001C12.5 5.00001 6.23596 4.99301 4.66896 5.40401C3.82896 5.63301 3.13496 6.32501 2.90296 7.18201C2.48996 8.74801 2.48596 11.996 2.48596 11.996C2.48596 11.996 2.48196 15.26 2.89196 16.81C3.12196 17.667 3.79696 18.344 4.65496 18.575C6.23696 19.005 12.485 19.012 12.485 19.012C12.485 19.012 18.75 19.019 20.316 18.609C21.172 18.379 21.85 17.703 22.083 16.846C22.497 15.281 22.5 12.034 22.5 12.034C22.5 12.034 22.52 8.76901 22.093 7.20301ZM10.496 15.005L10.501 9.00501L15.708 12.01L10.496 15.005Z' />
    </svg>
  );
};

export const TwitchIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Twitch</title>
      <path d='M4.765 3L3.5 6.236V19.459H8.002V21L10.533 21.85L12.925 19.459H16.583L21.506 14.535V3H4.765ZM19.817 13.691L17.004 16.505H12.502L10.111 18.896V16.505H6.313V4.688H19.817V13.691ZM17.005 7.924V12.847H15.317V7.924H17.005ZM12.503 7.924V12.847H10.815V7.924H12.503Z' />
    </svg>
  );
};

export const TikTokIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Tiktok</title>
      <path d='M19.964 6.95384C18.0435 6.95384 16.481 5.41672 16.481 3.52734C16.481 3.23607 16.2411 3 15.945 3H13.0741C12.7782 3 12.5381 3.23607 12.5381 3.52734V15.1067C12.5381 16.2174 11.6195 17.121 10.4904 17.121C9.36142 17.121 8.44291 16.2174 8.44291 15.1067C8.44291 13.9958 9.36142 13.0922 10.4904 13.0922C10.7865 13.0922 11.0265 12.8561 11.0265 12.5648V9.74052C11.0265 9.44939 10.7865 9.21318 10.4904 9.21318C7.18728 9.21318 4.5 11.857 4.5 15.1067C4.5 18.3563 7.18728 21 10.4904 21C13.7936 21 16.481 18.3563 16.481 15.1067V9.98112C17.5478 10.5407 18.735 10.8328 19.964 10.8328C20.26 10.8328 20.5 10.5968 20.5 10.3055V7.48119C20.5 7.19005 20.26 6.95384 19.964 6.95384Z' />
    </svg>
  );
};

export const DiscordIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <title>Discord</title>
      <path d='M10.093 10.9709C9.55102 10.9709 9.12402 11.4459 9.12402 12.0259C9.12402 12.6039 9.56102 13.0809 10.093 13.0809C10.634 13.0809 11.061 12.6039 11.061 12.0259C11.072 11.4449 10.634 10.9709 10.093 10.9709ZM13.561 10.9709C13.019 10.9709 12.592 11.4459 12.592 12.0259C12.592 12.6039 13.029 13.0809 13.561 13.0809C14.102 13.0809 14.529 12.6039 14.529 12.0259C14.528 11.4449 14.102 10.9709 13.561 10.9709Z' />
      <path d='M18.178 3H5.447C4.374 3 3.5 3.874 3.5 4.957V17.801C3.5 18.884 4.374 19.758 5.447 19.758H16.22L15.715 17.999L16.932 19.13L18.081 20.194L20.125 22V4.957C20.125 3.874 19.251 3 18.178 3ZM14.51 15.407C14.51 15.407 14.168 14.999 13.884 14.636C15.128 14.284 15.603 13.506 15.603 13.506C15.213 13.762 14.843 13.944 14.51 14.068C14.035 14.266 13.58 14.399 13.133 14.476C12.221 14.646 11.385 14.599 10.672 14.466C10.131 14.361 9.666 14.209 9.276 14.056C9.058 13.971 8.819 13.868 8.583 13.735C8.554 13.714 8.526 13.706 8.498 13.687C8.478 13.677 8.469 13.668 8.459 13.657C8.288 13.563 8.193 13.497 8.193 13.497C8.193 13.497 8.649 14.257 9.856 14.618C9.571 14.978 9.219 15.407 9.219 15.407C7.12 15.34 6.323 13.963 6.323 13.963C6.323 10.904 7.691 8.425 7.691 8.425C9.059 7.398 10.36 7.427 10.36 7.427L10.455 7.541C8.745 8.036 7.956 8.786 7.956 8.786C7.956 8.786 8.166 8.672 8.517 8.511C9.533 8.065 10.34 7.941 10.673 7.912C10.73 7.903 10.778 7.893 10.835 7.893C11.415 7.817 12.07 7.798 12.753 7.874C13.656 7.979 14.626 8.245 15.613 8.786C15.613 8.786 14.862 8.074 13.247 7.58L13.38 7.428C13.38 7.428 14.682 7.399 16.049 8.426C16.049 8.426 17.417 10.905 17.417 13.964C17.417 13.963 16.61 15.34 14.51 15.407Z' />
    </svg>
  );
};

'use client';

import React, { useState, useEffect } from 'react';
import CustomImage from '@/components/CustomImage';
import { ImageFragment, AdditionalImageFragment } from '@/gql';

import styles from './index.module.css';

interface ImageCycleProps {
  images: ReadonlyArray<ImageFragment> | ReadonlyArray<AdditionalImageFragment>;
  interval?: number; // Time in milliseconds between image changes, default is 1 second
  imageAlt?: string;
  imageClass?: string;
}

const ImageCycle: React.FC<ImageCycleProps> = ({ images, interval = 1000, imageClass, imageAlt }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    let imageSwitchInterval: number;

    const startCycle = () => {
      imageSwitchInterval = window.setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, interval);
    };

    if (currentImageIndex > 0) {
      startCycle();
    }

    return () => {
      clearInterval(imageSwitchInterval); // Clear interval on component unmount or if mouse leaves
    };
  }, [currentImageIndex, interval, images.length]);

  const handleMouseEnter = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleMouseLeave = () => {
    setCurrentImageIndex(0); // Reset to the first image
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.imageContainer}>
      {images.length > 0 && (
        <CustomImage
          src={images[currentImageIndex]?.src || ''}
          alt={imageAlt || ''}
          fill
          sizes='16rem'
          className={imageClass}
          fallbackImage={images[0]}
        />
      )}
    </div>
  );
};

export default ImageCycle;

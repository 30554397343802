'use client';

import { useEffect, useMemo } from 'react';
import { useStoreTheme } from '@/hooks/use-store-theme';

/**
 * Hook to dynamically set theme-related CSS variables based on the store's theme configuration.
 *
 * This hook applies CSS variables for primary buttons and quick view buttons,
 * ensuring that the theme dynamically adapts to the configuration specified in the store's theme.
 *
 * It leverages useMemo to optimize the computation of variables and useEffect to apply them
 * to the root HTML element.
 *
 * @returns void
 */
const useDynamicTheme = () => {
  const storeTheme = useStoreTheme();
  const storeScopes = storeTheme?.scopes?.storefront?.styles;

  // Button Scopes
  const buttonScope = storeScopes?.buttons?.primary;
  const quickViewScope = storeScopes?.buttons?.quickView;

  // Memoize primary button variables
  const primaryButtonVariables = useMemo(
    () => ({
      '--primary-button-background': buttonScope?.backgroundColor || 'var(--primary-ui)',
      '--primary-button-border': buttonScope?.borderColor || 'var(--transparent)',
      '--primary-text-color': buttonScope?.color || 'var(--primary-02)',
    }),
    [buttonScope]
  );

  // Memoize quick view button variables
  const quickViewButtonVariables = useMemo(
    () => ({
      '--quick-view-button-background': quickViewScope?.backgroundColor || 'var(--primary-02)',
      '--quick-view-button-border': quickViewScope?.borderColor || 'var(--neutral-20)',
      '--quick-view-text-color': quickViewScope?.color || 'var(--primary-01)',
    }),
    [quickViewScope]
  );

  // Apply CSS variables to the root element
  useEffect(() => {
    const root = document.documentElement;

    // Set primary button variables
    Object.entries(primaryButtonVariables).forEach(([key, value]) => {
      root.style.setProperty(key, value);
    });

    // Set quick view button variables
    Object.entries(quickViewButtonVariables).forEach(([key, value]) => {
      root.style.setProperty(key, value);
    });
  }, [primaryButtonVariables, quickViewButtonVariables]);
};

export default useDynamicTheme;

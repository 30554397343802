'use client';

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Link from 'next/link';
import MessageBannerItemPromo from '@/components/MessageBannerItemPromo';
import { PlayIcon, PauseIcon } from '@heroicons/react/24/solid';
import analytics from '@/lib/analytics';
import { useStoreData } from '@/hooks/use-store-data';
import { PromoMessage, isPromoMessage } from '@/types/promo-message';
import styles from './index.module.css';

export interface TextMessage {
  description: string;
  link?: string;
  linkText?: string;
}

export type BannerMessage = TextMessage | PromoMessage;

interface MessageBannerProps {
  messages: BannerMessage[];
}

const DELAYED_BY = 7000;

const MessageBanner: React.FC<MessageBannerProps> = ({ messages }) => {
  const [currentMessage, setCurrentMessage] = useState<BannerMessage>(messages[0]);
  const [key, setKey] = useState<number>(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let timer: number | undefined;
    let currentMessageIndex = 0;

    if (!isPaused && messages.length > 1) {
      timer = window.setInterval(() => {
        currentMessageIndex = (currentMessageIndex + 1) % messages.length;
        setCurrentMessage(messages[currentMessageIndex]);
        setKey((prevKey) => prevKey + 1);
      }, DELAYED_BY);
    }

    // Cleanup on unmount or when isPaused changes
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [messages, isPaused]);

  const { storeData } = useStoreData();
  const hasPromo = messages.some((message) => isPromoMessage(message));
  useEffect(() => {
    const payload = {
      messageNumber: messages.length,
      hasPromo,
    };
    analytics.track('messaging_banner', payload, storeData);
  }, [hasPromo, messages, storeData]);

  const handlePause = useCallback(() => {
    setIsPaused((v) => !v);
  }, []);

  const content = useMemo(() => {
    if (isPromoMessage(currentMessage)) {
      return <MessageBannerItemPromo promoMessage={currentMessage} />;
    } else {
      return (
        <>
          {currentMessage.description.length > 0 ? currentMessage.description : ''}
          {currentMessage.link && (
            <Link href={currentMessage.link} className={styles.link}>
              {currentMessage.linkText}
            </Link>
          )}
        </>
      );
    }
  }, [currentMessage]);

  const pauseButtonLabel = isPaused ? 'Resume messages' : 'Pause messages';
  const renderPauseButton = isPaused ? (
    <PlayIcon className={styles.iconClass} aria-hidden='true' />
  ) : (
    <PauseIcon className={styles.iconClass} aria-hidden='true' />
  );

  return (
    <div className={styles.container}>
      <div key={key} className={`${styles.message} fade-in-out`}>
        {content}
      </div>
      {messages.length > 1 && (
        <button type='button' aria-label={pauseButtonLabel} className={styles.pauseButton} onClick={handlePause}>
          {renderPauseButton}
        </button>
      )}
    </div>
  );
};

export default MessageBanner;

'use client';

import React, { createContext, useMemo } from 'react';
import { CollectionsV2Fragment } from '@/gql';

export type CollectionsState = {
  collections: CollectionsV2Fragment;
};

export const CollectionsContext = createContext<CollectionsState | undefined>(undefined);

export type CollectionsProviderProps = {
  children: React.ReactNode;
  collections: CollectionsV2Fragment;
};

export const CollectionsProvider: React.FC<CollectionsProviderProps> = ({ children, collections }) => {
  const memoizedValue = useMemo(() => {
    return { collections };
  }, [collections]);

  return <CollectionsContext.Provider value={memoizedValue}>{children}</CollectionsContext.Provider>;
};

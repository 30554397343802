'use client';

import React from 'react';
import Link from 'next/link';
import { StoreThemeSocialMediaFragment } from '@/gql';
import { useStoreTheme } from '@/hooks/use-store-theme';
import {
  DiscordIcon,
  FacebookIcon,
  GitHubIcon,
  InstagramIcon,
  LinkIcon,
  TikTokIcon,
  TwitchIcon,
  TwitterIcon,
  YouTubeIcon,
} from '@/components/Icons/SocialMediaIcons';

interface SocialMediaComponentProps {
  linkContainerClass: string;
  iconClassName: string;
  linkClassName: string;
}

const iconComponentsByName: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  discord: DiscordIcon,
  facebook: FacebookIcon,
  github: GitHubIcon,
  instagram: InstagramIcon,
  link: LinkIcon,
  tiktok: TikTokIcon,
  twitch: TwitchIcon,
  twitter: TwitterIcon,
  youtube: YouTubeIcon,
};

const SocialMediaComponent: React.FC<SocialMediaComponentProps> = ({
  linkContainerClass,
  iconClassName,
  linkClassName,
}) => {
  const storeTheme = useStoreTheme();
  const socialMediaArray: ReadonlyArray<StoreThemeSocialMediaFragment> = storeTheme?.brand?.socialMedia || [];

  if (socialMediaArray.length === 0) {
    return null;
  }

  return (
    <ul className={linkContainerClass}>
      {socialMediaArray?.map((item) => {
        const iconKey = item?.icon ? item.icon.toLowerCase() : '';
        const IconComponent = iconComponentsByName[iconKey];
        if (!IconComponent || !item?.url) {
          return null;
        }
        return (
          <li key={item.label || 'default-key'} className={linkClassName} aria-label={item.label || 'default-label'}>
            <Link href={item.url}>
              <span className='sr-only'>{item.label}</span>
              <IconComponent className={iconClassName} aria-hidden='true' />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialMediaComponent;

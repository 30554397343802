import { create } from 'zustand';

type NavMode = 'top' | 'bottom';

interface UiState {
  navMode: NavMode;
  setNavMode: (navMode: NavMode) => void;

  navUnderline: boolean;
  // reference counted calls, each call to navUnderlineInc() must
  // have a corresponding call to navUnderlineDec()
  navUnderlineInc: () => void;
  navUnderlineDec: () => void;
}

export const useUiState = create<UiState>((set) => {
  let navRefCount = 0;
  return {
    navMode: 'top',
    setNavMode: (navMode: NavMode) => {
      set({ navMode });
    },
    navUnderline: false,
    navUnderlineInc: () => {
      navRefCount += 1;
      if (navRefCount === 1) {
        set({ navUnderline: true });
      }
    },
    navUnderlineDec: () => {
      navRefCount -= 1;
      if (navRefCount === 0) {
        set({ navUnderline: false });
      }
    },
  };
});

'use client';

import React from 'react';
import { StoreThemeFragment } from '@/gql';
import LayoutComponent from '@/components/Layout';
import { useStoreTheme } from '@/hooks/use-store-theme';
import { isNullish } from '@/lib/utils';

/**
 * Helper to convert theme data into layout component options
 *
 * @param storeTheme
 * @returns
 */
function buildLayoutOptions(storeTheme?: StoreThemeFragment) {
  // layout header options
  const themeHeader = storeTheme?.content?.header;

  const headerOptions = {
    ...(!isNullish(themeHeader?.hideSearch)
      ? {
          hideSearch: Boolean(themeHeader?.hideSearch), // converts null to false
        }
      : undefined),
  };
  // layout footer options
  const themeFooter = storeTheme?.content?.footer;
  const footerOptions = {
    ...(!isNullish(themeFooter?.showSocialIcons)
      ? {
          showSocialIcons: Boolean(themeFooter?.showSocialIcons), // converts null to false
        }
      : undefined),
  };

  return {
    headerOptions,
    footerOptions,
  };
}
/**
 * Layout component wrapper which pulls potentially dynamic store theme data and feeds it to the layout component
 * ** Requires StoreThemeProvider context provider in the parent stack **
 *
 * @param props
 * @returns
 */
const StoreThemedLayout = ({ children }: { children: React.ReactNode }) => {
  const storeTheme = useStoreTheme();
  const { headerOptions, footerOptions } = buildLayoutOptions(storeTheme);

  return (
    <LayoutComponent headerOptions={headerOptions} footerOptions={footerOptions}>
      {children}
    </LayoutComponent>
  );
};

export default StoreThemedLayout;

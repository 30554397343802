'use client';

import { useContext } from 'react';
import { CollectionsContext } from '@/contexts/store-collections-context';
import { CollectionFragment } from '@/gql';

/**
 * Hook to manage collection options
 *
 * @returns The collections data
 */
export function useCollections(): {
  collections: ReadonlyArray<CollectionFragment>;
} {
  const collectionsContext = useContext(CollectionsContext);
  if (!collectionsContext) {
    throw new Error('useCollections() requires a parent CollectionsProvider');
  }

  const { collections } = collectionsContext;
  return { collections: collections?.collections || [] };
}

/**
 * Hook to manage custom collection options
 *
 * @returns The custom collections data
 */
export function useCustomCollections(): {
  customCollections: ReadonlyArray<CollectionFragment>;
} {
  const collectionsContext = useContext(CollectionsContext);
  if (!collectionsContext) {
    throw new Error('useCustomCollections() requires a parent CollectionsProvider');
  }

  const { collections } = collectionsContext;
  return { customCollections: collections?.customCollections || [] };
}

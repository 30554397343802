'use client';

import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CustomImage from '@/components/CustomImage';
import Overlay from '@/components/Overlay';
import { Currency } from '@/gql';
import { isCurrency } from '@/lib/gql';
import { CURRENCIES, getCurrencyDetail, getFulfillmentDetail, isFulfillmentOption } from '@/lib/preferences';
import { FULFILLMENT_OPTIONS, FulfillmentOption } from '@/types/preferences';
import { useUserPreferences } from '@/hooks/use-user-preferences';
import styles from './index.module.css';

const RegionAndCurrencyComponent: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm();

  const { fulfillment, currency, setFulfillment, setCurrency } = useUserPreferences();

  const [localFulfillment, setLocalFulfillment] = React.useState<FulfillmentOption>(fulfillment);
  const [localCurrency, setLocalCurrency] = React.useState<Currency>(currency);

  const [isOpen, seIsOpen] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      register('region');
      register('currency');

      setLocalFulfillment(fulfillment);
      setLocalCurrency(currency);

      setValue('region', fulfillment);
      setValue('currency', currency);
    }
  }, [register, fulfillment, currency, setValue, isOpen]);

  const openModal = useCallback(() => {
    seIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    seIsOpen(false);
  }, []);

  const handleFulfillmentChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    if (isFulfillmentOption(e.target.value)) {
      setLocalFulfillment(e.target.value);
    }
  }, []);

  const handleCurrencyChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    if (isCurrency(e.target.value)) {
      setLocalCurrency(e.target.value);
    }
  }, []);

  // const router = useRouter();
  const onSubmit = useCallback(() => {
    const somethingChanged = [setFulfillment(localFulfillment), setCurrency(localCurrency)].some((v) => v);
    closeModal();
    if (somethingChanged) {
      // new content load can be slow, force a full
      // reload with the new state so we're not showing
      // stale data
      // router.refresh();
      window.location.reload();
      // router.refresh();
    }
  }, [closeModal, localCurrency, localFulfillment, setCurrency, setFulfillment]);

  const flagCode = useMemo(() => {
    return getFulfillmentDetail(fulfillment)?.flagCountry?.toLocaleLowerCase() || '';
  }, [fulfillment]);

  return (
    <>
      <button
        aria-label='region-and-currency'
        type='button'
        className={styles.regionAndCurrencyButton}
        onClick={openModal}
      >
        {flagCode ? (
          <CustomImage
            className={styles.regionAndCurrencyImage}
            src={`https://flagcdn.com/32x24/${flagCode}.png`}
            width='25'
            height='25'
            alt='Country Picker country flag'
            loading='lazy'
            decoding='async'
          />
        ) : (
          <span className={`${styles.regionAndCurrencyTitle} pr-1`}>Other</span>
        )}
        <span className={styles.regionAndCurrencyTitle}>{currency}</span>
      </button>

      <Overlay show={isOpen} className={styles.container} onClose={closeModal}>
        <div className={styles.dialogContainer}>
          <div className={styles.dialog}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className={styles.dialogPanel}>
                <div className={styles.titleBar}>
                  <Dialog.Title as='h3' className={styles.title}>
                    Preferences
                  </Dialog.Title>
                  <button type='button' className={styles.closeButton} onClick={closeModal}>
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className={styles.closeIcon} aria-hidden='true' />
                  </button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                  <div className={styles.labelContainer}>
                    {/* eslint-disable-next-line */}
                    <label id='region-label' htmlFor='region'>
                      Select your fulfillment region
                    </label>
                    <div id='region-info' className={`group ${styles.iconContent}`}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <circle cx='12' cy='12' r='10' />
                        <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' />
                        <line x1='12' y1='17' x2='12.01' y2='17' />
                      </svg>
                      <span className={`${styles.helperText} group-hover:visible`}>
                        This option controls which country your order will be fulfilled from. This may affect factors
                        including price, shipping options and product availability.
                      </span>
                    </div>
                  </div>
                  <select
                    id='region'
                    name='region'
                    autoComplete='country-name'
                    onChange={handleFulfillmentChange}
                    className={styles.select}
                    aria-labelledby='region-label'
                    value={localFulfillment}
                  >
                    {FULFILLMENT_OPTIONS.map((currentOption: FulfillmentOption) => {
                      const { display, option } = getFulfillmentDetail(currentOption);
                      return (
                        <option key={option} value={option}>
                          {display}
                        </option>
                      );
                    })}
                  </select>

                  <div className={styles.labelContainer}>
                    {/* eslint-disable-next-line */}
                    <label id='currency-label' htmlFor='currency'>
                      Select your currency
                    </label>
                    <div id='currency-info' className={`group ${styles.iconContent}`}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <circle cx='12' cy='12' r='10' />
                        <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' />
                        <line x1='12' y1='17' x2='12.01' y2='17' />
                      </svg>
                      <span className={`${styles.helperText} group-hover:visible`}>
                        This option controls which currency your transactions will be charged in. Your bank might charge
                        additional fees for transactions in a foreign currency.
                      </span>
                    </div>
                  </div>
                  <select
                    id='currency'
                    name='currency'
                    autoComplete='currency'
                    onChange={handleCurrencyChange}
                    className={styles.select}
                    aria-labelledby='currency-label'
                    value={localCurrency}
                  >
                    {CURRENCIES.map((current: Currency) => {
                      const { display, description } = getCurrencyDetail(current);
                      return (
                        <option
                          key={current}
                          value={current}
                          title={description}
                          aria-label={description}
                        >{`${display}`}</option>
                      );
                    })}
                  </select>

                  <div className={styles.buttonContainer}>
                    <button type='button' className={styles.cancelButton} onClick={closeModal}>
                      Cancel
                    </button>
                    <button type='submit' className={styles.submitButton}>
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default RegionAndCurrencyComponent;

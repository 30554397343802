import { BasicProductFragment, ProductFragment } from '@/gql';
import { isNotNullish } from '@/lib/utils';
import useStoreBasicProductsState from '@/stores/store-basic-products-state';
import { positiveIntOr } from '@/lib/math';
import { productIdFromUrl } from '@/lib/navigation';

export type ProductRelationship = 'same-listing' | 'different-listing';

/**
 * Find related basic product information for input products (exludings those products from the result)
 * This is a first pass to get something running, it will most certainly need refinement
 *
 * @param products The products to find products related to
 * @param relationship The relationship
 * @param padTo Try to add any products to reach this count, even if it violates the relationship
 */
export const useRelatedProducts = (
  products: ProductFragment[],
  relationship: ProductRelationship,
  padTo?: number
): {
  products: BasicProductFragment[];
  loading: boolean;
} => {
  const storeProducts = useStoreBasicProductsState((state) => state.products);
  const storeProductsLoading = useStoreBasicProductsState((state) => state.loading);

  // identify a product by it's listing and product id
  // nulish inputs return undefined, doing it here to clean up usage
  const productKeyGen = (listingSlug?: string | null, productId?: number | null) =>
    isNotNullish(listingSlug) && isNotNullish(productId) ? `${listingSlug}.${productId}` : undefined;

  // reduce products to unique listing slugs and product ids
  const [listingSlugs, productKeys] = products.reduce(
    (accum, current) => {
      const [listingAccum, productKeyAccum] = accum;
      if (current.url) {
        listingAccum.add(current.url);
        const key = productKeyGen(current.url, current.primaryProductId);
        if (key) {
          productKeyAccum.add(key);
        }
      }
      return accum;
    },
    [new Set<string>(), new Set<string>()]
  );

  // build the filters
  type ProductFilter = (candidate: BasicProductFragment) => boolean;
  const relationshipFilter: ProductFilter =
    relationship === 'same-listing'
      ? (candidate) => isNotNullish(candidate.listingSlug) && listingSlugs.has(candidate.listingSlug)
      : (candidate) => isNotNullish(candidate.listingSlug) && !listingSlugs.has(candidate.listingSlug);
  const filter: ProductFilter = (candidate) => {
    const productId = (candidate.url && productIdFromUrl(candidate.url)) || undefined;
    const key = productKeyGen(candidate.listingSlug, productId);
    return isNotNullish(key) && !productKeys.has(key) && relationshipFilter(candidate);
  };

  // filter the products
  const filteredProducts = storeProducts.filter(filter);

  // pad with any products?
  const targetCount = positiveIntOr(padTo, 0);
  if (filteredProducts.length < targetCount) {
    // add the accumulated products to known/exluded product id set
    filteredProducts.forEach((fp) => {
      const key = productKeyGen(fp.listingSlug, fp.id);
      if (key) {
        productKeys.add(key);
      }
    });

    // try to get to the desired count
    for (let i = 0; i < storeProducts.length && filteredProducts.length < targetCount; i += 1) {
      const candidateProduct = storeProducts[i];
      const key = productKeyGen(candidateProduct.listingSlug, candidateProduct.id);
      if (isNotNullish(key) && !productKeys.has(key)) {
        productKeys.add(key);
        filteredProducts.push(candidateProduct);
      }
    }
  }

  // filter and return
  return {
    products: filteredProducts,
    loading: storeProductsLoading,
  };
};

import { isNullish } from '../utils';

/**
 * Convert a dirty number to a positive integer or return a default value.  The input
 * value will be tested for non-nullishned, clamped to an integer using Math.floor(),
 * and finally test for being less than zero (negative zero is considered positive)
 *
 *
 * @param value The value to test and coerce
 * @param defaultValue The value to return if value fails the sniff test
 * @returns value as a positive integer (as number) or the default value
 */
export const positiveIntOr = (value: number | undefined | null, defaultValue: number) => {
  if (isNullish(value) || Number.isNaN(value)) {
    return defaultValue;
  }

  // clamp to int and check positive
  const intVal = Math.floor(value);
  if (intVal < 0) {
    return defaultValue;
  }

  return intVal;
};

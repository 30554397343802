'use client';

import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import BurgerButton from '@/components/BurgerButton';
import NavComponent from '@/components/NavComponent';
import Overlay from '@/components/Overlay';
import SocialMediaComponent from '@/components/SocialMediaComponent';
import { useCollectionsNavigation } from '@/hooks/use-collections-navigation';
import styles from './index.module.css';

interface BurgerComponentProps {
  isBurgerShowing: boolean;
}

const BurgerComponent: React.FC<BurgerComponentProps> = ({ isBurgerShowing = false }) => {
  const collectionsNavigation = useCollectionsNavigation(true);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <BurgerButton isBurgerShowing={isBurgerShowing} onClick={handleOpenDialog} data-testid='burger-button' />
      <Overlay show={isOpen} className={styles.dialogMain} onClose={() => setIsOpen(false)}>
        <div className={styles.dialogContent}>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <Dialog.Panel className={styles.dialogPanel}>
              <div className={styles.dialogPanelClose}>
                <button type='button' className={styles.closePanelButton} onClick={() => setIsOpen(false)}>
                  <span className='sr-only'>Close menu</span>
                  <XMarkIcon className={styles.iconSize} aria-hidden='true' />
                </button>
              </div>
              <NavComponent
                ariaLabel='Left side navigation'
                isCurrentLink={false}
                navMainClass={styles.navMain}
                collectionsNavigation={collectionsNavigation}
                currentLinkContainerClass={styles.collectionContent}
                activeCurrentLinkClass={styles.active}
                currentLinkDefaultClass={styles.collectionDefault}
                collectionLinkClass={styles.collectionLink}
                subCollectionActiveClass={styles.active}
                subCollectionDefaultClass={styles.subCollectionDefault}
                onLinkClick={() => setIsOpen(false)}
                placement='collection_mobile_menu'
              >
                <SocialMediaComponent
                  linkContainerClass={styles.socialMediaIconsContainer}
                  linkClassName={styles.socialMediaLinks}
                  iconClassName={styles.iconSize}
                />
              </NavComponent>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Overlay>
    </>
  );
};

export default BurgerComponent;

import { create } from 'zustand';
import { getAllStoreBasicProductsProgressive } from '@/api';
import { BasicProductFragment, Currency } from '@/gql';
import { getFulfillmentDetail } from '@/lib/preferences';
import { DEFAULT_FULFILLMENT_REGION } from '@/constants';
import { FulfillmentOption } from '@/types/preferences';

type StoreBasicProductsState = {
  storeSlug: string;
  loading: boolean;
  products: readonly BasicProductFragment[];
};

type StoreBasicProductsActions = {
  reset: () => void;
  setup: (storeSlug: string, fulfillment: FulfillmentOption, currency: Currency, previewMode?: boolean) => void;
};

const defaultState: StoreBasicProductsState = {
  storeSlug: '',
  loading: false,
  products: [],
};

const useStoreBasicProductsState = create<StoreBasicProductsState & StoreBasicProductsActions>()((set, get) => {
  // the active AbortController for a running request
  let productLoadAbort: AbortController | undefined;

  // abort current running product load (if applicable)
  const abortProductLoad = () => {
    if (productLoadAbort) {
      productLoadAbort.abort();
      productLoadAbort = undefined;
    }
  };

  // main product loading routine
  const loadProducts = (
    storeSlug: string,
    fulfillment: FulfillmentOption,
    currency: Currency,
    previewMode?: boolean
  ) => {
    // abort any pending loading
    abortProductLoad();

    // reset and bail on invalid store slug
    if (!storeSlug) {
      set({ products: [], loading: false });
      return;
    }

    // create an abort controller for this request
    const localLoadAbort = new AbortController();
    // make it the active abort controller
    productLoadAbort = localLoadAbort;
    // set loading/empty state (setting loading state may be redundant in the debounce flow)
    set({ products: [], loading: true });

    // helper to set products if we're the active request and not aborted
    const setStoreProducts = (products: readonly BasicProductFragment[]) => {
      // if we're still the active request (and we weren't aborted)
      if (productLoadAbort === localLoadAbort && !localLoadAbort?.signal.aborted) {
        set({ products: products || [] });
      }
    };

    const { region = DEFAULT_FULFILLMENT_REGION } = getFulfillmentDetail(fulfillment);

    // kick off the full product load with progressive data (page order retained)
    getAllStoreBasicProductsProgressive(
      storeSlug,
      region,
      currency,
      previewMode ? 'any' : undefined, // visibility
      { progress: setStoreProducts, signal: productLoadAbort.signal }
    )
      .then((result) => {
        if (productLoadAbort === localLoadAbort) {
          // If the call was aborted, or the data was not found (likely the store slug is invalid)
          // keep any loaded data as valid, if perhaps incomplete, and leave reseting to the aborter
          if (result !== 'aborted' && result !== 'not found') {
            // redundant as progress should have reported all, but seems tidier to use the solid return value
            setStoreProducts(result);
          }
        }
      })
      .finally(() => {
        // clear loading/productLoadAbort if we're the active request
        if (productLoadAbort === localLoadAbort) {
          productLoadAbort = undefined;
          set({ loading: false });
        }
      });
  };

  // kill any running product load and reset to initial state
  const reset = () => {
    // kill any running load
    abortProductLoad();
    // default state
    set({ ...defaultState });
  };

  // currently there is no need to expose preview mode, so track
  // it locally to be able to tell if a product load is needed
  let lastPreviewMode: boolean = false;
  let lastFulfillment: FulfillmentOption | undefined;
  let lastCurrency: Currency | undefined;
  const setup = (storeSlug: string, fulfillment: FulfillmentOption, currency: Currency, previewMode?: boolean) => {
    // normalize previewMode as a bool
    const normalizedPreviewMode = Boolean(previewMode);
    // anything change?
    if (
      get().storeSlug !== storeSlug ||
      lastPreviewMode !== normalizedPreviewMode ||
      lastFulfillment !== fulfillment ||
      lastCurrency !== currency
    ) {
      set({ storeSlug });
      lastPreviewMode = normalizedPreviewMode;
      lastFulfillment = fulfillment;
      lastCurrency = currency;
      loadProducts(storeSlug, fulfillment, currency, normalizedPreviewMode);
    }
  };

  return {
    ...defaultState,
    reset,
    setup,
  };
});

export default useStoreBasicProductsState;

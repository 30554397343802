'use client';

import React from 'react';
import Link from 'next/link';
import CustomImage from '@/components/CustomImage';
import { useStoreTheme } from '@/hooks/use-store-theme';
import { normalizeAssetPathUrl } from '@/lib/utils';

import styles from './index.module.css';

interface StoreLogoProps {
  storLogoStyle: string;
}

const StoreLogo: React.FC<StoreLogoProps> = ({ storLogoStyle }) => {
  const storeTheme = useStoreTheme();
  const storeLogoUrl = storeTheme?.content?.header?.logo || '';
  const storeName = storeTheme?.meta?.storeName || '';
  const storeSlug = storeTheme?.storeKey || '';

  const storeLogoMaxHeight = storeTheme?.styles?.header?.logo?.maxHeight;

  const LogoOrName = normalizeAssetPathUrl(storeLogoUrl, storeSlug) ? (
    <div className={styles.storeLogoContainer} style={{ maxHeight: storeLogoMaxHeight || '' }}>
      <CustomImage
        src={normalizeAssetPathUrl(storeLogoUrl, storeSlug) as any}
        alt='store logo'
        fill
        className={styles.storeLogoImage}
        data-testid='store-logo'
      />
    </div>
  ) : (
    <h3 data-testid='store-name'>{storeName}</h3>
  );

  return (
    <div className={`${styles.storeLogoDiv} ${storLogoStyle}`}>
      <Link href='/'>
        <span className='sr-only'>{storeName}</span>
        {LogoOrName}
      </Link>
    </div>
  );
};

export default StoreLogo;

import React, { useState, useEffect } from 'react';
import { MEDIUM_SCREEN_SIZE } from '@/constants';
import useWindowDimensions from '@/hooks/use-window-dimensions';
import { buildListingRoute, productIdFromUrl } from '@/lib/navigation';
import { buildSortedProductAdditionalImages } from '@/lib/product';
import { BasicProductFragment } from '@/gql';
import ProductVisualDisplay from '@/components/ProductVisualDisplay';

import styles from './index.module.css';

interface SearchWithProductsProps {
  searchResults: readonly BasicProductFragment[];
  searchValue: string;
  onProductClicked?: () => void;
}

const SearchWithProducts: React.FC<SearchWithProductsProps> = ({ searchResults, searchValue, onProductClicked }) => {
  const { width } = useWindowDimensions();

  const [screenSize, setScreenSize] = useState(width || 0);
  const [viewAll, setViewAll] = useState(false);
  const [displayProducts, setDisplayProducts] = useState<readonly BasicProductFragment[]>([]);

  const handleViewAll = () => setViewAll((prevViewAll) => !prevViewAll);

  useEffect(() => {
    setScreenSize(width || 0);
  }, [width]);

  useEffect(() => {
    setDisplayProducts(viewAll ? searchResults : searchResults.slice(0, 4));
  }, [searchResults, viewAll]);

  const renderProductVisualDisplay = (product: BasicProductFragment, index: number) => {
    const orderedImages = buildSortedProductAdditionalImages(product);

    return (
      <ProductVisualDisplay
        key={product.id}
        productId={String(product.id) || ''}
        productTitle={product.name || ''}
        productType={product.productName || ''}
        images={orderedImages}
        listingUrl={buildListingRoute({
          listingSlug: product.listingSlug ?? undefined,
          productId: productIdFromUrl(product.url || ''),
        })}
        price={product.price || ''}
        index={index || 0}
        placement='Search'
        onProductClicked={onProductClicked}
      />
    );
  };

  const viewAllButton = () => {
    return (
      <button type='button' onClick={handleViewAll}>
        View All
      </button>
    );
  };

  const isSmallScreen = screenSize < MEDIUM_SCREEN_SIZE;

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <h3>{`${searchResults.length} results for "${searchValue}"`}</h3>
          {!isSmallScreen && searchResults.length > 4 && !viewAll && viewAllButton()}
        </div>
        <div className={styles.productGrid}>
          {displayProducts.map((product, index) => renderProductVisualDisplay(product, index))}
        </div>
      </div>
      {isSmallScreen && searchResults.length > 4 && !viewAll && viewAllButton()}
    </>
  );
};

export default SearchWithProducts;

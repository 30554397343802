'use client';

import React, { useCallback } from 'react';
import Link from 'next/link';
import { ImageFragment, AdditionalImageFragment } from '@/gql';
import Currency from '@/components/Currency';
import ImageCycle from '@/components/ImageCycle';
import analytics from '@/lib/analytics';

import styles from './index.module.css';

interface ProductVisualDisplayProps {
  productId: string;
  productTitle: string;
  images: ReadonlyArray<ImageFragment> | ReadonlyArray<AdditionalImageFragment>;
  listingUrl: string;
  productType?: string;
  price?: string;
  currency?: string;
  actionElement?: React.ReactNode;
  onProductClicked?: () => void;
  index?: number;
  placement?: string | '';
  productTileContainerClass?: string;
}

const ProductVisualDisplay: React.FC<ProductVisualDisplayProps> = ({
  productId,
  productTitle,
  images,
  listingUrl,
  productType,
  price,
  currency,
  actionElement,
  onProductClicked,
  index,
  placement,
  productTileContainerClass,
}) => {
  const onLinkClick = useCallback(() => {
    if (onProductClicked) {
      onProductClicked();
    }
    if (placement && placement === 'Search') {
      const position = index ? index + 1 : 0;
      analytics.track('search_results_clicked', { price, position, listingUrl });
    }
  }, [onProductClicked, index, price, listingUrl, placement]);

  let productPrice;
  if (price && !currency) {
    productPrice = price;
  } else if (!price || !currency) {
    productPrice = <span>Price not available</span>;
  } else {
    productPrice = <Currency amount={parseFloat(price)} currencyCode={currency} />;
  }

  return images?.length > 0 ? (
    <div className={`${styles.productTile} ${productTileContainerClass}`}>
      {actionElement}
      <div data-testid='product-image-container' className={styles.productImageContainer}>
        <Link key={productId} href={listingUrl} onClick={onLinkClick} role='link'>
          <span className='sr-only'>{productTitle}</span>
          <ImageCycle
            images={images}
            imageAlt={productTitle}
            imageClass={`${styles.productImage} group-hover:opacity-75`}
          />
        </Link>
      </div>
      <div className={styles.productInfoContainer}>
        <div className={styles.productContent}>
          <span aria-hidden='true' className={styles.hiddenProductName} />
          <span className={styles.productName}>{productTitle}</span>
          <span className={styles.productTypeClass}>{productType}</span>
        </div>
        <span className={styles.productPrice}>{productPrice}</span>
      </div>
    </div>
  ) : null;
};

export default ProductVisualDisplay;

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/StoreClientRoot/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/StoreThemedLayout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CollectionsProvider"] */ "/app/src/contexts/store-collections-context/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreDataProvider"] */ "/app/src/contexts/store-data-context/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreThemeProvider"] */ "/app/src/contexts/store-theme-context/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserPreferencesProvider"] */ "/app/src/contexts/user-preferences-context/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/tailwind/primary/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/CartCheckoutButton/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/SearchBar/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/ReactPhoneInput/index.css");

import React, { ReactNode } from 'react';
import Footer, { FooterOptions } from '@/components/Footer';
import Header, { HeaderOptions } from '@/components/Header';
import AgeVerify from '../AgeVerify';
import styles from './index.module.css';

interface LayoutProps {
  children: ReactNode;
  mainClassName?: string;
  headerOptions?: HeaderOptions;
  footerOptions?: FooterOptions;
}

const Layout: React.FC<LayoutProps> = ({ children, mainClassName = '', headerOptions, footerOptions }) => {
  const mainClass = [styles.main, mainClassName].join(' ');

  return (
    <div className={styles.container}>
      <AgeVerify />
      <Header options={headerOptions} />
      <div className='flex-1'>
        <main className={mainClass}>{children}</main>
      </div>
      <Footer options={footerOptions} />
    </div>
  );
};

export default Layout;

'use client';

import React, { useCallback } from 'react';
import Link from 'next/link';
import { useStoreData } from '@/hooks/use-store-data';
import analytics from '@/lib/analytics';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { useCartQuantity } from '@/hooks/use-cart-quantity';
import styles from './index.module.css';

interface CartComponentProps {
  cartComponentStyle?: React.CSSProperties;
}

const CartComponent: React.FC<CartComponentProps> = ({ cartComponentStyle }) => {
  const { storeData } = useStoreData();
  const handleClick = useCallback(() => {
    analytics.track('cart_link_clicked', { source: 'Header' }, storeData);
  }, [storeData]);
  const cartQuantity = useCartQuantity();

  return (
    <div className={styles.cartComponent} style={cartComponentStyle}>
      <Link href='/cart' className={styles.cartLink} onClick={handleClick}>
        <ShoppingBagIcon className={styles.shoppingBagIcon} aria-hidden='true' />
        <span className={styles.cartQuantityText}>{cartQuantity}</span>
        <span className={styles.srOnly}>items in cart, view bag</span>
      </Link>
    </div>
  );
};

export default CartComponent;

import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import debounce from 'lodash-es/debounce';
import { BasicProductFragment } from '@/gql';
import { searchBasicProducts } from '@/lib/product';
import useStoreBasicProductsState from '@/stores/store-basic-products-state';

interface SearchState {
  searchQuery: string;
  showSearch: boolean;
  loading: boolean;
  searchResults: readonly BasicProductFragment[];
}

interface SearchActions {
  setSearchQuery: (searchQuery: string) => void;
  setShowSearch: (showSearch: boolean) => void;
}

// private setter, not exposed as part of state
let setSearchResults: (searchResults: readonly BasicProductFragment[], loading: boolean) => void;

const useSearchState = create<SearchState & SearchActions>()(
  subscribeWithSelector((set) => {
    // bump search result setter into file scope
    setSearchResults = (searchResults: readonly BasicProductFragment[], loading: boolean) => {
      set({ searchResults, loading });
    };
    return {
      searchQuery: '',
      showSearch: false,
      loading: false,
      searchResults: [],
      setShowSearch: (showSearch) => set({ showSearch }),
      setSearchQuery: (searchQuery) => set({ searchQuery }),
    };
  })
);

// search engine, separate from state for tidiness,
// to let it subscribe to data sources consistently,
// but mostly because zustand does not have computed
// values (and the available middleware have very low
// usage)
// client only
if (typeof window !== 'undefined') {
  (() => {
    const updateSearchResults = () => {
      /* istanbul ignore if */
      if (!setSearchResults) {
        return; // should never happen
      }

      const { products, loading } = useStoreBasicProductsState.getState();
      const { searchQuery } = useSearchState.getState();

      const searchResults = searchBasicProducts(products, searchQuery);
      setSearchResults(searchResults, loading);
    };

    // lightly debounced search execution
    const dirtySearch = debounce(updateSearchResults, 100, { maxWait: 1000 });

    // listen for interesting changes
    useStoreBasicProductsState.subscribe(dirtySearch);
    useSearchState.subscribe((state) => state.searchQuery, dirtySearch);

    // initial run
    dirtySearch();
  })();
}

export default useSearchState;

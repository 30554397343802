'use client';

import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useStoreBasicProductsState from '@/stores/store-basic-products-state';
import { useStoreData } from '@/hooks/use-store-data';
import useDynamicTheme from '@/hooks/use-dynamic-theme';
import { useUserPreferences } from '@/hooks/use-user-preferences';
import analytics from '@/lib/analytics';
import { usePreviewStoreTheme } from '@/hooks/use-preview-store-theme';
import { appleVerification } from '@/api';

type Props = {
  children?: ReactElement;
};

/**
 * Component to add top level store specifc initialization on the client side in the [storeSlug]
 *
 * @param props
 * @returns
 */
const StoreClientRoot: React.FC<Props> = ({ children }) => {
  const { storeData, storeSlug: backupSlug } = useStoreData();
  const setupBasicProductsState = useStoreBasicProductsState((state) => state.setup);
  const ldClient = useLDClient();

  // Apply dynamic theme using the pre-defined hook
  useDynamicTheme();

  const storeSlug = storeData.slug || backupSlug;

  useEffect(() => {
    if (ldClient) {
      const context = {
        kind: 'store',
        key: storeData?.slug ?? undefined,
        name: storeData?.name || undefined,
      };
      ldClient.identify(context);
    }
  }, [ldClient, storeData?.name, storeData?.slug]);

  // try to validate the domain for apple payments
  useEffect(() => {
    appleVerification(window.location.host);
  }, []);

  // Get the official preview store theme
  // note: we're using immediate mode in an ssr flow, meaning we can't use
  // previewStoreTheme for the immediate render, but as we only use it in a
  // useEffect() (which can't affect this render) we can get away with it and
  // save a potentially expensive duplicated pull of all store product info
  const previewStoreTheme = usePreviewStoreTheme(true /* immediate mode */);
  const previewMode = Boolean(previewStoreTheme?.preview);

  const { fulfillment, currency } = useUserPreferences();

  useEffect(() => {
    // kick off basic product loading for this store
    // if this proves too expensive on the backend we can load on demand,
    // but as it's currently planned to be used for search and product
    // recommendation there is a fair chance we'll need it
    setupBasicProductsState(storeSlug, fulfillment, currency, previewMode);
  }, [currency, fulfillment, previewMode, setupBasicProductsState, storeSlug]);

  const analyticsCleanup = useMemo(() => {
    analytics.addContext(storeData);
    return () => {
      analytics.removeContext(storeData);
    };
  }, [storeData]);

  useEffect(() => analyticsCleanup, [analyticsCleanup]);

  return children ?? null;
};

export default StoreClientRoot;

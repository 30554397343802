'use client';

import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCollections, useCustomCollections } from '@/hooks/use-collections';
import { isNotNullish } from '@/lib/utils';
import { buildCollectionRoute } from '@/lib/navigation';
import useActiveCollectionState from '@/stores/active-collection-state';
import {
  CollectionsNavigation,
  CollectionNavigationItem,
  CollectionNavigationRootItem,
  CollectionNavigationSubItem,
} from '@/types/collections-navigation';

// Helper to verify params and build the url for this collection
const collectionToCollectionNavigationItem = ({
  name,
  slug,
}: {
  name?: string | null;
  slug?: string | null;
}): CollectionNavigationItem | undefined => {
  if (isNotNullish(name) && isNotNullish(slug)) {
    return {
      name,
      slug,
      url: buildCollectionRoute(slug),
    };
  }

  return undefined;
};

/**
 * Hook to manage collection state an navigation
 *
 * @param addExplore Set to true to add an 'Explore' pseudo collection as the first entry (with a slug of '')
 * @param activeCollectionSlugOverride If defined this will set the active collection slug used by collection displays to know which is active
 * @param forceAddCollections Flag to include collections conditionally
 * @returns The collection navigation data and, when applicable, the active collection slug
 */
export function useCollectionsNavigation(
  addExplore: boolean = false,
  forceAddCollections: boolean = false
): CollectionsNavigation {
  const { collectionsUi } = useFlags();
  // pull active collection from context
  const { collections } = useCollections();
  const { customCollections } = useCustomCollections();

  // get the active collection slug
  const { activeCollectionSlug } = useActiveCollectionState((state) => state);

  // convert collections into navigation items
  const navigationCollections = useMemo<CollectionNavigationRootItem[]>(() => {
    const rootItems: CollectionNavigationRootItem[] = [];

    if (addExplore) {
      rootItems.push({
        name: 'Explore',
        slug: '',
        url: '/',
      });
    }

    let collectionsAccum = collections;

    if (!collectionsUi || customCollections.length === 1) {
      // not using collections ui, or single custom collection, add custom collections to normal collection stream
      collectionsAccum = [...customCollections, ...collectionsAccum];
    }

    const hasMultipleCustomCollections = collectionsUi && customCollections.length > 1;
    const currentCustomCollections: CollectionNavigationSubItem[] =
      (hasMultipleCustomCollections &&
        customCollections.map((customCollection) => collectionToCollectionNavigationItem(customCollection))) ||
      [];
    if (hasMultipleCustomCollections || forceAddCollections) {
      // Add collections record after ensuring name and slug are strings
      rootItems.push({
        name: 'Collections',
        slug: 'collections',
        url: '/collections',
        collections: currentCustomCollections,
      });
    }

    collectionsAccum?.forEach((rawCollection) => {
      // Any sub collection items?
      const subCollection: CollectionNavigationSubItem[] = [];
      rawCollection?.collections?.forEach((rawSubItem) => {
        const subEntry = collectionToCollectionNavigationItem(rawSubItem);
        if (subEntry) {
          subCollection.push(subEntry);
        }
      });

      // Build up the root collection items
      const entry = collectionToCollectionNavigationItem(rawCollection);
      if (entry) {
        rootItems.push({
          ...entry,
          collections: subCollection?.length ? subCollection : undefined,
        });
      }
    });

    return rootItems;
  }, [addExplore, collections, collectionsUi, customCollections, forceAddCollections]);

  // finally, memoize the return value
  const collectionsNavigation = useMemo(() => {
    return { collections: navigationCollections, activeSlug: activeCollectionSlug };
  }, [activeCollectionSlug, navigationCollections]);

  return collectionsNavigation;
}

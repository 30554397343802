import React, { forwardRef } from 'react';
import NavComponent from '@/components/NavComponent';
import { CollectionsNavigation } from '@/types/collections-navigation';

import styles from './index.module.css';

interface NavTopProps {
  show?: boolean;
  enableNavUnderline?: boolean;
  collectionsNavigation: CollectionsNavigation;
}

const NavTop = forwardRef<HTMLDivElement, NavTopProps>(
  ({ show = true, enableNavUnderline, collectionsNavigation }, ref) => {
    return (
      <NavComponent
        ref={ref}
        collectionsNavigation={collectionsNavigation}
        ariaLabel='Top navigation'
        navMainClass={show ? styles.componentWrapper : styles.componentHidden}
        navContentClass={styles.navContentContainer}
        collectionActiveClass={styles.collectionActiveClass}
        collectionInActiveClass={styles.collectionInActiveClass}
        collectionDefaultClass={`${styles.collectionDefaultClass} ${enableNavUnderline && styles.addBorder} group`}
        collectionLinkClass={styles.collectionLinkClass}
        subCollectionClass={`${styles.subCollectionClass} group-hover:flex`}
        subCollectionActiveClass={styles.subCollectionActiveClass}
        subCollectionDefaultClass={styles.subCollectionDefaultClass}
        isSubCollectionDisplay
      />
    );
  }
);

NavTop.displayName = 'NavTop';

export default NavTop;

'use client';

import { Fragment, useCallback, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/navigation';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AGE_VERIFY_REDIRECT } from '@/constants';
import Overlay from '@/components/Overlay';
import styles from './index.module.css';

const AgeVerify = () => {
  const router = useRouter();
  const { showAgeVerify } = useFlags();
  const [isVerified, setIsVerified] = useState(() =>
    Boolean(typeof window !== 'undefined' && window.localStorage.getItem('isVerified'))
  );
  const [isEighteen, setIsEighteen] = useState(false);
  const [isRightState, setIsRightState] = useState(false);

  const handleYes = useCallback(() => {
    // tracker.track('age_verify.yes.clicked');
    window.localStorage.setItem('isVerified', 'true');
    setIsVerified(true);
  }, []);
  const handleNo = useCallback(() => {
    // tracker.track('age_verify.no.clicked');
    router.push(AGE_VERIFY_REDIRECT);
  }, [router]);

  // bail if age verification isn't required or has been verified
  if (!showAgeVerify || isVerified) {
    return null;
  }

  // all the boxes checked?
  const canProceed = isEighteen && isRightState;

  return (
    <Overlay show className={styles.container} onClose={handleNo}>
      <div className={styles.dialogContainer}>
        <div className={styles.dialog}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className={styles.dialogPanel}>
              <div className={styles.titleBar}>
                <Dialog.Title as='h3' className={styles.title}>
                  Age Verification
                </Dialog.Title>
                <button type='button' className={styles.closeButton} onClick={handleNo}>
                  <span className='sr-only'>Close</span>
                  <XMarkIcon className={styles.closeIcon} aria-hidden='true' />
                </button>
              </div>
              <form className={styles.form}>
                <p className={styles.formHeader}>
                  Caution, this site contains sexually explicit content. Kindly certify the statements below:
                </p>
                <div className={styles.labelContainer}>
                  <label htmlFor='checkbox-is-eighteen' className={styles.label}>
                    <input
                      type='checkbox'
                      id='checkbox-is-eighteen'
                      checked={isEighteen}
                      onChange={() => {
                        setIsEighteen(!isEighteen);
                      }}
                    />
                    <div className={styles.labelText}>I am 18 years of age or older.</div>
                  </label>
                </div>
                <div className={styles.labelContainer}>
                  <label htmlFor='checkbox-right-state' className={styles.label}>
                    <input
                      id='checkbox-right-state'
                      type='checkbox'
                      checked={isRightState}
                      onChange={() => {
                        setIsRightState(!isRightState);
                      }}
                    />
                    <div className={styles.labelText}>I do not currently reside in the State of Louisiana or Utah.</div>
                  </label>
                </div>
                <div className={styles.buttonContainer}>
                  <button type='button' className={styles.cancelButton} onClick={handleNo}>
                    Exit
                  </button>
                  <button type='button' className={styles.submitButton} disabled={!canProceed} onClick={handleYes}>
                    Proceed
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Overlay>
  );
};

export default AgeVerify;

import {
  StorePromoCodeFixedValueFragment,
  StorePromoCodeFreeShippingFragment,
  StorePromoCodePercentageFragment,
} from '@/gql';
import {
  isStorePromoCodeFixedValueFragment,
  isStorePromoCodeFreeShippingFragment,
  isStorePromoCodePercentageFragment,
} from '@/lib/gql';

export interface PromoMessage {
  promoCode: StorePromoCodePercentageFragment | StorePromoCodeFreeShippingFragment | StorePromoCodeFixedValueFragment;
}

/**
 * Simple type guard for PromoMessage
 *
 * @param candidate The maybe a PromoMessage to test
 * @returns true if the candidate appears to be a PromoMessage
 */
export function isPromoMessage(candidate: any): candidate is PromoMessage {
  const promoMessage: PromoMessage = candidate as PromoMessage;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    promoMessage &&
      typeof promoMessage === 'object' &&
      (isStorePromoCodePercentageFragment(promoMessage.promoCode) ||
        isStorePromoCodeFreeShippingFragment(promoMessage.promoCode) ||
        isStorePromoCodeFixedValueFragment(promoMessage.promoCode))
  );
}

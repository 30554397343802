import React, { useCallback } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import analytics from '@/lib/analytics';
import { useStoreData } from '@/hooks/use-store-data';
import styles from './index.module.css';

interface BurgerButtonProps {
  onClick: () => void;
  isBurgerShowing: boolean;
}

const BurgerButton: React.FC<BurgerButtonProps> = ({ isBurgerShowing = false, onClick, ...props }) => {
  const { storeData } = useStoreData();
  const handleClick = useCallback(() => {
    analytics.track('mobile_nav_clicked', {}, storeData);
    onClick();
  }, [onClick, storeData]);

  const currentIsBurgerShowing = isBurgerShowing ? styles.displayFlex : '';

  return (
    <div className={`${styles.container} ${currentIsBurgerShowing}`} data-label='burger-button'>
      <button type='button' className={styles.button} onClick={handleClick} {...props}>
        <span className={styles.srOnly}>Open menu</span>
        <Bars3Icon className={styles.icon} aria-hidden='true' />
      </button>
    </div>
  );
};

export default BurgerButton;

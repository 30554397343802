import { create } from 'zustand';

type ActiveCollectionCookie = symbol;

type ActiveCollectionStackEntry = {
  collectionSlug: string;
  cookie: ActiveCollectionCookie;
};

type ActiveCollectionState = {
  stack: ActiveCollectionStackEntry[];
  activeCollectionSlug: string;
};

type ActiveCollectionActions = {
  push(collectionSlug: string): ActiveCollectionCookie;
  pop(cookie: ActiveCollectionCookie): boolean;
  clear(): void;
};

const defaultState: ActiveCollectionState = {
  stack: [],
  activeCollectionSlug: '',
};

// volatile (in memory only) active collection state tracking
const useActiveCollectionState = create<ActiveCollectionState & ActiveCollectionActions>((set, get) => ({
  ...defaultState,
  push(collectionSlug: string): ActiveCollectionCookie {
    const cookie = Symbol(collectionSlug);
    // push new entry onto back of stack
    const newStack = [
      ...get().stack,
      {
        collectionSlug,
        cookie,
      },
    ];
    // adopt new state
    set({
      stack: newStack,
      activeCollectionSlug: collectionSlug,
    });
    return cookie;
  },
  pop(cookie: ActiveCollectionCookie): boolean {
    // find the victim
    const currentStack = get().stack;
    const victimIdx = currentStack.findIndex((entry) => entry.cookie === cookie);
    if (victimIdx >= 0) {
      // remove the victim
      const newStack = [...currentStack];
      newStack.splice(victimIdx, 1);
      // adopt new state
      set({
        stack: newStack,
        activeCollectionSlug: newStack.length > 0 ? newStack[newStack.length - 1].collectionSlug : '',
      });
      return true;
    }
    return false;
  },
  clear(): void {
    set({
      ...defaultState,
    });
  },
}));

export default useActiveCollectionState;

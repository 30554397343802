'use client';

import { useStoreSlug } from '../use-store-slug';
import { useCartMeta, useValidatedCartProducts } from '../use-cart-products';

export const useCartQuantity = (): number => {
  const storeSlug = useStoreSlug();
  const validatedCartProducts = useValidatedCartProducts(storeSlug);
  const cartMeta = useCartMeta(validatedCartProducts !== 'loading' ? validatedCartProducts : []);
  // will return 0 while 'loading'
  return cartMeta.quantity;
};

import React, { useState, useEffect, ReactNode, useMemo, useCallback } from 'react';
import { PromoMessage } from '@/types/promo-message';
import CurrencyComponent from '@/components/Currency';
import {
  isStorePromoCodeFixedValueFragment,
  isStorePromoCodeFreeShippingFragment,
  isStorePromoCodePercentageFragment,
} from '@/lib/gql';
import { isNotNullish } from '@/lib/utils';
import { safeUnreachable } from '@/lib/tsutils';
import styles from './index.module.css';

const getDiscountDescription = (promoCode: PromoMessage['promoCode']): ReactNode => {
  if (isStorePromoCodePercentageFragment(promoCode)) {
    return `${promoCode.percentage}% off your order`;
  } else if (isStorePromoCodeFreeShippingFragment(promoCode)) {
    return 'Free Shipping on your order';
  } else if (isStorePromoCodeFixedValueFragment(promoCode)) {
    const { amount, currency } = promoCode.value || {};
    if (isNotNullish(amount) && isNotNullish(currency)) {
      return (
        <div className='flex space-x-1'>
          <CurrencyComponent amount={amount / 100} currencyCode={currency} />
          <div>off your order</div>
        </div>
      );
    } else {
      return null;
    }
  }

  // This wont compile if the above conditional don't cover all expected
  // inputs for promoCode, but will run and return null
  return safeUnreachable(promoCode, null);
};

type Props = {
  promoMessage: PromoMessage;
};

const COPIED_TEXT = 'Copied!';

const MessageBannerItemPromo = ({ promoMessage }: Props) => {
  const { promoCode } = promoMessage || {};
  const { code = '' } = promoCode || {};

  const [copySuccess, setCopySuccess] = useState(false);
  const [buttonText, setButtonText] = useState<string>('');

  const copyCodeToClipboard = useCallback(() => {
    // tracker.track('whitelabel.promo_banner.clicked');
    navigator.clipboard.writeText(code);
    setCopySuccess(true);
  }, [code]);

  useEffect(() => {
    if (copySuccess) {
      const timerId = setTimeout(() => {
        setButtonText(code);
        setCopySuccess(false);
      }, 3000);
      setButtonText(COPIED_TEXT);
      return () => clearTimeout(timerId);
    }
    return undefined;
  }, [copySuccess, code]);

  useEffect(() => {
    setButtonText(code);
    setCopySuccess(false);
  }, [code]);

  // Turn promo code into something we can display
  const discountDescription = useMemo(() => getDiscountDescription(promoCode), [promoCode]);
  if (!discountDescription) {
    return null;
  }

  return (
    <div key={`${code}`} className={styles.container}>
      <p className='promocode_content'>Use code:</p>
      <button
        type='button'
        className={styles.promoCodeButton /* 'promocode_content promocode_button' */}
        onClick={() => copyCodeToClipboard()}
      >
        {buttonText}
      </button>
      <p className={styles.hideInMobile}>at checkout</p>
      <p className='flex space-x-1'>for</p>
      <div className='underline'>{discountDescription}</div>
    </div>
  );
};

export default MessageBannerItemPromo;

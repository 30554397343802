'use client';

import React, { useCallback, forwardRef, useState, useEffect } from 'react';
import Link from 'next/link';
import { CollectionsNavigation } from '@/types/collections-navigation';
import classnames from 'classnames';
import { isNotNullish, isNullish } from '@/lib/utils';
import analytics from '@/lib/analytics';
import { Metrics } from '@/types/metrics';
import { useStoreData } from '@/hooks/use-store-data';

interface NavComponentProps {
  collectionsNavigation: CollectionsNavigation;
  ariaLabel: string;
  navMainClass: string;
  navContentClass?: string;
  collectionActiveClass?: string;
  collectionInActiveClass?: string;
  collectionDefaultClass?: string;
  collectionLinkClass?: string;
  subCollectionClass?: string;
  subCollectionActiveClass?: string;
  currentLinkContainerClass?: string;
  subCollectionInactiveClass?: string;
  subCollectionDefaultClass?: string;
  activeCurrentLinkClass?: string;
  currentLinkDefaultClass?: string;
  isSubCollectionDisplay?: boolean;
  isCurrentLink?: boolean;
  placement?: string;
  children?: React.ReactNode;
  onLinkClick?: () => void;
}

const NavComponent = forwardRef<HTMLElement, NavComponentProps>(
  (
    {
      collectionsNavigation,
      ariaLabel,
      navMainClass,
      navContentClass,
      collectionActiveClass,
      collectionInActiveClass = '',
      collectionDefaultClass,
      collectionLinkClass,
      activeCurrentLinkClass,
      currentLinkDefaultClass,
      subCollectionClass,
      subCollectionActiveClass,
      subCollectionInactiveClass = '',
      subCollectionDefaultClass,
      currentLinkContainerClass,
      isSubCollectionDisplay = true,
      isCurrentLink = true,
      placement,
      children,
      onLinkClick,
    },
    ref
  ) => {
    const { collections, activeSlug: activeCollectionSlug } = collectionsNavigation;

    /**
     * Helper to test if a given collection is the active one
     *
     * @param testCollection
     * @returns
     */
    const isActiveSlug = (testCollection: { slug?: string | null }): boolean => {
      // special case for 'Explore' pseudo collection, where the activeCollection slug should be
      // undefined and the collection slug should be an empty string
      if (isNullish(activeCollectionSlug) && testCollection.slug === '') {
        return true;
      }
      // the normal case, return true if the inputs are valid and the slugs match
      return Boolean(
        isNotNullish(activeCollectionSlug) &&
          isNotNullish(testCollection.slug) &&
          activeCollectionSlug === testCollection.slug
      );
    };

    const { storeData } = useStoreData();
    const onProductLinkClick = useCallback(
      (name: string, url: string) => {
        const eventType = placement ? `${placement}_clicked` : 'collection_desktop_nav_clicked';
        analytics.track(eventType as keyof Metrics, { collectionName: name, collectionUrl: url }, storeData);
        if (onLinkClick) {
          onLinkClick();
        }
      },
      [placement, storeData, onLinkClick]
    );

    const [fadeInClass, setFadeInClass] = useState('delayed-fade-in');
    useEffect(() => {
      // Remove the delayed-fade-in class once the component has been rendered
      setFadeInClass('');
    }, []);

    return (
      <nav ref={ref} aria-label={ariaLabel} className={navMainClass}>
        <div className={navContentClass}>
          {collections?.map((collection) => {
            // Check if any sub-collection's slug matches the active slug
            const isSubCollectionActive = collection?.collections?.some(isActiveSlug);
            const collectionActive = isSubCollectionActive || isActiveSlug(collection); // Consider parent collection active if its own slug matches OR any of its sub-collection's slug matches
            let activeClassName = collectionActive ? collectionActiveClass : collectionInActiveClass;
            let currentClassName = classnames(activeClassName, collectionDefaultClass);
            const currentLinkClassName = isActiveSlug(collection) ? activeCurrentLinkClass : currentLinkDefaultClass;

            return (
              <div
                key={collection.name}
                className={`${(isCurrentLink && currentClassName) || currentLinkContainerClass} ${fadeInClass}`}
              >
                <Link
                  key={collection.slug}
                  href={collection.url}
                  prefetch
                  className={`${!isCurrentLink && currentLinkClassName} ${collectionLinkClass}`}
                  onClick={() => onProductLinkClick(collection.name, collection.url)}
                >
                  {collection.name}
                </Link>
                {isSubCollectionDisplay && (
                  <div className={subCollectionClass}>
                    {collection?.collections?.map((subCollection) => {
                      activeClassName = isActiveSlug(subCollection)
                        ? subCollectionActiveClass
                        : subCollectionInactiveClass;
                      currentClassName = classnames(subCollectionDefaultClass, activeClassName);

                      return (
                        <Link
                          key={subCollection.slug}
                          href={subCollection.url}
                          prefetch
                          className={currentClassName}
                          onClick={() => onProductLinkClick(subCollection.name, subCollection.url)}
                        >
                          {subCollection.name}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {children}
      </nav>
    );
  }
);

NavComponent.displayName = 'NavComponent';

export default NavComponent;
